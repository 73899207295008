<template>
    <div v-if="page && module && widgets">
        <h2 class="modal-section-title">select module</h2>
        <div class="widget-preview--wrapper">
            <div
                v-for="widget in widgets"
                :key="widget.id"
                class="mb-4 cursor-pointer"
            > 
                <modal-link
                    route="widgets.create"
                    :params="{ websiteId: websiteId, pageSlug: pageSlug, widgetId: widget.id}"
                >
                    <div class="border text-xs">
                        <p class="border-b p-2 font-bold">{{ widget.name }}</p>
                        <img :src="getImageUrl(widget.component)" />
                    </div>
                </modal-link>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";
    import ModalNavigation from "@/mixins/ModalNavigation";
    import ModalLink from "@/components/ui/modals/ModalLink";

    export default {
        components: { ModalLink },
        mixins: [ ModalNavigation ],
        data: () => {
            return {
                websiteId: null,
                page: null,
                pageSlug: null,
                module: null,
                widgets: []
            }
        },
        computed: {
            ...mapGetters({
                getPageBySlugAndWebsiteId: 'pages/getPageBySlugAndWebsiteId',
                getWidgetsByModuleName: 'widgets/getWidgetsByModuleName',
            })
        },
        methods: {
            getImageUrl(widgetComponentName) {
                try {
                    return require('@/assets/widgets/' + widgetComponentName +'.png');
                }
                catch(err) {
                    return require('@/assets/widgets/NA.png');
                }
            },
        },
        mounted() {
            this.websiteId = this.$route.params.websiteId;
            this.pageSlug = this.$route.params.pageSlug;
            this.module = this.$route.params.moduleName;

            this.page = this.getPageBySlugAndWebsiteId(this.pageSlug, this.websiteId );
            this.widgets = this.getWidgetsByModuleName(this.module);
            this.setActiveModalTitle(this.module.toLowerCase());
        }
    }
</script>
<style scoped>
    .widget-preview--wrapper{
        display:grid;
        grid-template-columns: repeat(auto-fit, minmax(375px, 1fr));
        grid-gap: 2rem;
    }
    @media (min-width: 1675px){
        .widget-preview--wrapper{
            grid-template-columns: 1fr 1fr;
        }
    }
</style>